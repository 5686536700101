import { useEffect, useState } from 'react';
import './App.css';
import ChargeItem from './Models/ChargeItem';
import { PayPalScriptProvider, PayPalButtons, ReactPayPalScriptOptions, BraintreePayPalButtons, PayPalButtonsComponentProps } from "@paypal/react-paypal-js";
import PayItem from './Models/PayItem';

interface OrderData {
  id: string;
  details?: Array<{
    issue: string;
    description: string;
  }>;
  debug_id?: string;
}

function App() {

  let baseUrl = "https://possystem-api-sandbox.fiskaltrust.eu/v2/cart/";
  //let baseUrl = "https://localhost:7252/v2/cart/";
  let [chargeItems, setChargeItems] = useState<ChargeItem[]>([]);
  let [payItems, setPayItems] = useState<PayItem[]>([]);
  const urlParams = new URLSearchParams(window.location.search);
  const journeyid = urlParams.get('journeyid');
  const initialOptions: ReactPayPalScriptOptions = {
    clientId: "AbgY5446uwNu9VfFGPktyQoSe23N6UAyLjUb5_w3R-w-i2qFlDMo_1rYenWKIzpx1Vf3CqK3GqPA4c_t",
    currency: "EUR",
    intent: "authorize"
  };

  function createOrderS(data: any, actions: any, citems: ChargeItem[]) {
    console.log("createOrder  called", citems)

    return actions.order.create({
      intent: "AUTHORIZE",
      purchase_units: [
        {
          amount: {
            value: citems.reduce((total, item) => total + item.Amount, 0).toString(),
            currency_code: "EUR",
          },
        },
      ]
    });
  }

  useEffect(() => {
    const interval = setInterval(() => {
      fetch(`${baseUrl}${journeyid}`)
        .then((response) => response.json())
        .then((data) => {
          setChargeItems(data.cbChargeItems);
          setPayItems(data.cbPayItems);
        });
    }, 1000);

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, [])

  if (chargeItems == null || chargeItems.length === 0) {
    return <div className="w-screen h-screen flex justify-center">
      No chargeitms yet
    </div>
  }

  let tax = chargeItems.reduce((total, item) => total + item.VATAmount, 0);
  let gross = chargeItems.reduce((total, item) => total + item.Amount, 0);
  let net = gross - tax;


  return (
    <div className="w-screen h-screen flex justify-center">
      <div className="bg-white w-5/12 h-min mt-10">
        <main className="bg-gray-300 p-5">

          <div className="mx-auto grid max-w-lg grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
            <div className="mx-auto w-full max-w-lg">

              <div className="flow-root">
                <ul role="list" className="my-6">
                  {chargeItems.map((product, i) => (
                    <li key={i} className="mt-5 space-y-6 text-sm font-medium">
                      <div className="flex justify-between">
                        <span>{product.Description}</span>
                        <span className="text-gray-900">{product.Amount} €</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <dl className="mt-10 text-sm">
            <div className="flex items-center justify-between py-1">
              <dt className="text-gray-600">Subtotal</dt>
              <dd className="font-medium text-gray-900">{net > 0 ? net.toLocaleString() + " €" : ""}</dd>
            </div>
            <div className="flex items-center justify-between py-1">
              <dt className="text-gray-600">Tax</dt>
              <dd className="font-medium text-gray-900">{tax > 0 ? tax.toLocaleString() + " €" : ""}</dd>
            </div>
            <div className="border-t-2 flex items-center justify-between py-2">
              <dt className="text-base font-medium text-gray-900">Total</dt>
              <dd className="text-base font-medium text-gray-900">{gross > 0 ? gross.toLocaleString() + " €" : ""}</dd>
            </div>
          </dl>

          <div className="mx-auto grid max-w-lg grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
            <div className="mx-auto w-full max-w-lg">

              <div className="flow-root">
                <ul role="list" className="my-6">
                  {payItems.map((product, i) => (
                    <li key={i} className="mt-5 space-y-6 text-sm font-medium">
                      <div className="flex justify-between">
                        <span>{product.Description}</span>
                        <span className="text-gray-900">{product.Amount} €</span>
                        <code>
                          {JSON.stringify(product.ftPayItemCaseData)}
                        </code>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div className="mx-auto w-full max-w-lg mt-10">
            <div className="mt-10">
              <PayPalScriptProvider options={initialOptions}>
                <PayPalButtons style={{ layout: "horizontal" }}
                  createOrder={(o, a) => createOrderS(o, a, chargeItems)}
                  onApprove={async (
                    data,
                    actions
                  ) => {
                    try {
                      const response = await fetch(
                        `${baseUrl}${journeyid}/authorize`,
                        {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify({
                            paypalorderid: data.orderID,
                          })
                        }
                      );
                      const orderData = await response.json();
                      console.log(orderData);
                    } catch (error) {
                      console.error(error);
                      alert(
                        `Sorry, your transaction could not be processed...${error}`
                      );
                    }
                  }} />
              </PayPalScriptProvider>
            </div>
          </div>
        </main>
      </div >
    </div>
  );
}



export default App;
